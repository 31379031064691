/* eslint-disable no-self-compare */
/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import config from "../../config/config";
import { useFormik } from "formik";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing, TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import swal from "sweetalert";
import modal from "bootstrap/js/src/modal";
import * as Yup from "yup";
import { colors } from "@material-ui/core";

const initialValues = {
  role: 3
};

export function MyPage14() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataOrder1, setDataOrder1] = useState([]);
  const [dataOrder2, setDataOrder2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  let cazz = [];

  useEffect(() => {
    getRoleMagazzino();
  }, []);
  const user = useSelector((state) => state.auth.user);

  function GetDataOrder1() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "POST",
      body: JSON.stringify({
        id_user: user.id
      })
    };

    fetch(config.apiUrl + "order/GenerateOrder1Brt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading(false);
        } else {
          setLoading(false);
          setDataOrder1(result);
        }
      });
  }

  function GetDataOrder3() {
    setLoading1(true);
    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "POST",
      body: JSON.stringify({
        id_user: user.id
      })
    };

    fetch(config.apiUrl + "order/GenerateOrder3Brt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading1(false);
        } else {
          setLoading1(false);
          //console.log(result);
          setDataOrder1(result);
        }
      });
  }


  function GetDataOrder7() {
    setLoading2(true);
    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "POST",
      body: JSON.stringify({
        id_user: user.id
      })
    };

    fetch(config.apiUrl + "order/GenerateOrder7Brt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading2(false);
        } else {
          setLoading2(false);
          console.log(result);
          setDataOrder1(result);
        }
      });
  }

  function GetDataOrder10() {
    setLoading3(true);
    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "POST",
      body: JSON.stringify({
        id_user: user.id
      })
    };

    fetch(config.apiUrl + "order/GenerateOrder10Brt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading3(false);
        } else {
          setLoading3(false);
          console.log(result);
          setDataOrder1(result);
        }
      });
  }

  function getRoleMagazzino() {

    const requestOptions = {
      headers: {
        Authorization: "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      }, method: "GET"
    };

    fetch(config.apiUrl + "order/GetAllRoleMagazzino.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {

        } else {
          console.log(result);
          console.log(result);
          setDataOrder2(result);
        }
      });
  }


  if (null !== dataOrder1) {
    dataOrder1.map((el) => {
      return el.map((es) => {
        return cazz.push(es);
      });
    });
  }

  const [sorting, setSorting] = useState([
    { columnName: "product_reference", direction: "asc" }]
  );
  const [selection, setSelection] = useState([]);
  const [selection2, setSelection2] = useState([]);
  let dataSelected = [];
  let dataSelected2 = [];

  if (null !== dataOrder1) {
    dataOrder1.filter((el, key) => selection.includes(key));
  }

  if (null !== dataSelected) {
    dataSelected.filter((el, key) => selection2.includes(key));
  }


  const [columns] = useState([
    { name: "product_reference", title: "Referenza Prodotto" },
    { name: "firstname", title: "Nome" },
    { name: "lastname", title: "Cognome" },
    { name: "id_order", title: "Id Ordine" },
    { name: "product_name", title: "Nome Prodotto" },
    { name: "product_quantity", title: "Quantità prodotto" },
    {
      name: "color", title: "color"
    }
  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "product_reference", width: 180 },
    { columnName: "firstname", width: 100 },
    { columnName: "lastname", width: 100 },
    { columnName: "id_order", width: 100 },
    { columnName: "product_name", width: 380 },
    { columnName: "product_quantity", width: 150 },
    { columnName: "color", width: 0 }

  ]);

  const [columns2] = useState([
    { name: "product_reference", title: "Referenza Prodotto" },
    { name: "product_quantity", title: "Quantità prodotto" },
    { name: "id_order", title: "Id Ordine" },
    { name: "product_name", title: "Nome Prodotto" }
  ]);

  const [defaultColumnWidths2] = useState([
    { columnName: "product_reference", width: 80 },
    { columnName: "product_quantity", width: 50 },
    { columnName: "id_order", width: 80 },
    { columnName: "product_name", width: 200 }
  ]);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      // eslint-disable-next-line no-alert
      onClick={() => alert(JSON.stringify(row))}
      style={{
        ...styles[row.color]
      }}
    />
  );

  const styles = {
    blue: {
      backgroundColor: "blue"
    },
    green: {
      backgroundColor: "green"
    },
    purple: {
      backgroundColor: "purple"
    },
    orange: {
      backgroundColor: "orange"
    },
    violet: {
      backgroundColor: "violet"
    },
    red: {
      backgroundColor: "red"
    },
    gray: {
      backgroundColor: "gray"
    },
    blueViolet: {
      backgroundColor: "blueViolet"
    },
    aqua: {
      backgroundColor: "aqua"
    }
  };


  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const RegistrationSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      console.log(values.role);
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
        },
        method: "POST",
        body: JSON.stringify({
          data: dataSelected2,
          role: values.role
        })
      };

      fetch(config.apiUrl + "order/sendToDevice.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result !== false) {
            swal({
                title: "Ordine creato!",
                text: "OK",
                icon: "success",
                type: "Success"
              }
            ).then(function() {
              window.location.reload();
            });
          } else {
            swal({
                title: "Ordine non Creato!",
                text: "Controlla l'inserimento corretto",
                icon: "error",
                type: "Error"
              }
            ).then(function() {
              window.location.reload();
            });
          }
        }).catch(() => {
        setLoading(false);
        setSubmitting(false);
      });
    }
  });

  return (
    <>
      <div className="container">
        <div className="row">
         <span className="font-weight-bold">
           Total rows selected:
           {"  "}
           {selection.length}
        </span>
        </div>
      </div>
      <div className="card">
        <Container className="mt-5">
          <Row className="mt-5">
            <Col>
              <button
                type="submit"
                onClick={GetDataOrder1}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                style={{ background: "#2f2d77", color: "#ffffff" }}
              >
                <span>Genera 1 ordine</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </Col>
            <Col>
              <button
                type="submit"
                onClick={GetDataOrder3}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                style={{ background: "#2f2d77", color: "#ffffff" }}
              >
                <span>Genera 3 ordini</span>
                {loading1 && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </Col>
            <Col>
              <button
                type="submit"
                onClick={GetDataOrder7}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                style={{ background: "#2f2d77", color: "#ffffff" }}
              >
                <span>Genera 7 ordini</span>
                {loading2 && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </Col>
            <Col>
              {" "}
              <button
                type="submit"
                onClick={GetDataOrder10}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                style={{ background: "#2f2d77", color: "#ffffff" }}
              >
                <span>Genera 10 ordini</span>
                {loading3 && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </Col>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <div className="card">
                  <Grid
                    rows={cazz}
                    columns={columns2}>
                    {/*<PagingState*/}
                    {/*  defaultCurrentPage={0}*/}
                    {/*  pageSize={15}*/}
                    {/*/>*/}
                    {/*<IntegratedPaging />*/}
                    <SortingState
                      sorting={sorting}
                      onSortingChange={setSorting}
                    />
                    <IntegratedSorting />
                    <SelectionState
                      selection={selection2}
                      onSelectionChange={setSelection2}
                    />
                    <Table rowComponent={TableRow} />
                    <TableColumnResizing
                      defaultColumnWidths={defaultColumnWidths2}
                    />
                    <TableHeaderRow />
                    <TableSelection
                      selectByRowClick
                    />
                    {/*<PagingPanel />*/}
                  </Grid>
                </div>
              </form>
            </Modal>
          </Row>
        </Container>
        <div className="card">
          <Grid
            rows={cazz}
            columns={columns}
          >
            {/*<PagingState*/}
            {/*  defaultCurrentPage={0}*/}
            {/*  //pageSize={15}*/}
            {/*/>*/}
            {/*<IntegratedPaging />*/}
            <SortingState
              sorting={sorting}
              onSortingChange={setSorting}
            />
            <IntegratedSorting />
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
            <Table rowComponent={TableRow} />
            <TableColumnResizing
              defaultColumnWidths={defaultColumnWidths}
            />
            <TableHeaderRow />
            <IntegratedSelection />
            <TableSelection
              showSelectAll
            />
            {/*<PagingPanel />*/}
          </Grid>
        </div>
      </div>
    </>
  );
}


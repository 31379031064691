import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { MyPage2 } from "./pages/MyPage2";
import { MyPage3 } from "./pages/MyPage3";
import { MyPage4 } from "./pages/MyPage4";
import { MyPage5 } from "./pages/MyPage5";
import { MyPage6 } from "./pages/MyPage6";
import { MyPage7 } from "./pages/MyPage7";
import { MyPage8 } from "./pages/MyPage8";
import { MyPage9} from "./pages/MyPage9";
import { MyPage10 } from "./pages/MyPage10";
import { MyPage11 } from "./pages/MyPage11";
import { MyPage12 } from "./pages/MyPage12";
import { MyPage13 } from "./pages/MyPage13";
import { MyPage14 } from "./pages/MyPage14";
import { MyPage15 } from "./pages/MyPage15";
import { MyPageTest } from "./pages/MyPageTest";
import { DashboardPage } from "./pages/DashboardPage";
import { Faq } from "./modules/Auth/pages/Faq";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/product" component={MyPage} />
        <ContentRoute path="/order" component={MyPage2} />
        <ContentRoute path="/ordertosend" component={MyPage3} />
        <ContentRoute path="/ordertosendbrt" component={MyPage14} />
        <ContentRoute path="/adduser" component={MyPage4} />
        <ContentRoute path="/closeworkdaybrt" component={MyPage5} />
        <ContentRoute path="/zplBrt" component={MyPage15} />
        <ContentRoute path="/orderwharehausegls" component={MyPage6} />
        <ContentRoute path="/orderwharehausebrt" component={MyPage13} />
        <ContentRoute path="/createorder" component={MyPage7} />
        <ContentRoute path="/glsDayClose" component={MyPage8} />
        <ContentRoute path="/brtorder" component={MyPage9} />
        <ContentRoute path="/brtconferm" component={MyPage10} />
        <ContentRoute path="/dispositivo" component={MyPage11} />
        <ContentRoute path="/zplgls" component={MyPage12} />
        <ContentRoute path="/test" component={MyPageTest} />
        <ContentRoute path="/faq" component={Faq} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

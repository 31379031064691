import React, { useEffect, useMemo, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import Container from "react-bootstrap/Container";
// eslint-disable-next-line no-restricted-imports
import Row from "react-bootstrap/Row";
// eslint-disable-next-line no-restricted-imports
import Col from "react-bootstrap/Col";
import config from "../../config/config";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableHeaderRow,
  TableSelection
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  IntegratedPaging, IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState
} from "@devexpress/dx-react-grid";
import swal from "sweetalert";
import { element } from "prop-types";

const initialValues = {
  id_parcella: ''
};


export function MyPage15(props) {
  const [loading, setLoading] = useState(false);
  const [dataOrder, setDataOrder] = useState([]);
  let cazz = [];
  const [print_zpl, set_print_zpl] = useState([]);

  useEffect(() => {
    GetCWD();
  }, []);

  const RegistrationSchema = Yup.object().shape({
    id_parcella: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Inserisci Contatore Progressivo Valido!")
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  function GetCWD() {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
      },
      method: "GET"
    };

    fetch(config.apiUrl + "order/CloseWorkDayBrt.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result === false) {
          setLoading(false);
        } else {
          setLoading(false);
          setDataOrder(result);
        }
      });
  }

  dataOrder.map((element) => {
    return cazz.push(element);
  });



  const [sorting, setSorting] = useState([{ columnName: "product_reference", direction: "asc" }]);
  const [selection, setSelection] = useState([]);
  let dataSelected = dataOrder.filter((el, key) => selection.includes(key));

  const [columns] = useState([

    { name: "id_parcella", title: "id Parcella" },
    { name: "referenza_corriere", title: "Referenza Corriere" },
    { name: "parcell_id_brt", title: "Parcell id brt" },
    { name: "date_created", title: "Date Created" }
  ]);

  const [defaultColumnWidths] = useState([
    { columnName: "id_parcella", width: 120 },
    { columnName: "referenza_corriere", width: 70 },
    { columnName: "parcell_id_brt", width: 120 },
    { columnName: "date_created", width: 100 }
  ]);

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      setLoading(true);
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2"
        },
        method: "POST",
        body: JSON.stringify({
          id_parcella: values.id_parcella
        })
      };
      fetch(config.apiUrl + "order/TestZplBrt.php", requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `FileName.txt`,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        })
    }
  });


  return (
    <>
      <form
        onSubmit={formik.handleSubmit}
      >
        <div className="form-group fv-plugins-icon-container">
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="id_parcella"
              type="text"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "id_parcella"
              )}`}
              name="id_parcella"
              {...formik.getFieldProps("id_parcella")}
            />
            {formik.touched.id_parcella && formik.errors.id_parcella ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.id_parcella}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="form-group d-flex flex-wrap flex-center">
          <div className="btnDiv">
            <button
              type="submit"
              //disabled={formik.isSubmitting || !formik.isValid}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              style={{ background: "#2f2d77", color: "#ffffff" }}
            >
              <span>Zpl</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}</button>
          </div>
        </div>
      </form>
      <div className="container">
        <div className="row">
         <span className="font-weight-bold">
           Total rows selected:
           {"  "}
           {selection.length}
        </span>
        </div>
      </div>
      <div className="card">
        <Grid
          rows={cazz}
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            pageSize={15}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={setSorting}
          />
          <IntegratedSorting />
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <Table />
          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
          />
          <TableHeaderRow />
          <IntegratedSelection />
          <TableSelection
            showSelectAll
          />
          <PagingPanel />
        </Grid>
      </div>
    </>
  );
}
